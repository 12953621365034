import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Result } from "antd";
import { Header } from "antd/lib/layout/layout";

const Safety = () => {
    const [sendRequest, setSendRequest] = useState(false);
	const onFinish = (values) => {
		// Lógica para enviar la solicitud de eliminación de datos al servidor
		setSendRequest(true);
	};

	return (
		<>
			<div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
				<div className="gx-header-horizontal-top">
					<Header className="gx-header-horizontal-main gx-header-horizontal-dark">
						<div className="gx-header-horizontal-main-flex">
							<div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
								<i
									className="gx-icon-btn icon icon-menu"
									/* onClick={() => {
										dispatch(toggleCollapsedSideNav(!navCollapsed));
									}} */
								/>
							</div>
							<a className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
								<img alt="" src={require("assets/images/w-logo.png")} />
							</a>
							<a className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
								<img
									alt=""
									src={require("assets/images/logo.png")}
									width={180}
								/>
							</a>
						</div>
					</Header>
				</div>
			</div>
			{sendRequest ? 
            
            <Result
                status="success"
                title="Solicitud enviada con éxito"
                subTitle="Se notificará a su correo"
                extra={[
                <Button type="primary" key="console" onClick={() => window.open("https://novacita.com", "_self", "")}>
                    Salir
                </Button>,
                ]}
            />
            : <Row justify="center" align="middle" style={{ height: "100vh" }}>
				<Col xs={24} sm={16} md={12} lg={10} xl={8}>
					<div style={{textAlign: "center", marginBottom: "30px"}}>
						<h2>Solicitud eliminación de cuenta</h2>
					</div>
					<Form
						name="dataDeletionForm"
						initialValues={{ remember: true }}
						onFinish={onFinish}
						labelCol={{ span: 24 }} // Esto coloca las etiquetas sobre los campos de entrada
						wrapperCol={{ span: 24 }}
					>
						<Form.Item
							label="Correo electrónico"
							name="username"
							rules={[{ required: true, message: "Por favor, ingresa el correo" },
                            { type: "email", message: "Por favor, ingresa un correo electrónico válido"}]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Motivo de la Eliminación"
							name="reason"
							rules={[{ required: true, message: "Por favor, ingresa el motivo de la eliminación" }]}
						>
							<Input.TextArea />
						</Form.Item>

						<Form.Item wrapperCol={{ span: 24, offset: 0 }} style={{textAlign: "center"}}>
							<Button type="primary" htmlType="submit">
              Enviar Solicitud
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row> }
		</>
	);
};

export default Safety;
