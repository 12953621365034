import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Divider, Popover, Typography} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { useHistory } from "react-router";
import { CreditCardOutlined, PoweroffOutlined, ProfileOutlined, ScheduleOutlined, SolutionOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';

const {Text} = Typography;

const UserInfo = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(({auth}) => auth.authUser);
  const widtInitial = useSelector(({common}) => common.width);
  const [widthScreen, setWidthScreen] =useState(widtInitial);

  window.addEventListener('resize', () => {
    setWidthScreen(window.innerWidth);
  })

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {widthScreen <= 480 ? 
      <li onClick={() => history.push('/account')}><Text strong style={{fontSize: '14px', color: "black"}}>{ user ? user.fullname.toUpperCase(): ''}</Text></li>
      : widthScreen > 481 && widthScreen <= 768 ? 
      null
      : null}
      <Divider><Text strong>Acceso Rápido</Text></Divider>
      <li onClick={() => history.push('/bookings')}><ScheduleOutlined /> Reservas/Certificados</li>
      <li onClick={() => history.push('/payments')}><CreditCardOutlined /> Pagos</li>
      <li onClick={() => history.push('/subaccounts')}><TeamOutlined /> Subcuentas</li>
      <li onClick={() => history.push('/prescriptions')}><ProfileOutlined /> Recetas</li>
      <li onClick={() => history.push('/history')}><SolutionOutlined /> Historia Clínica</li>
      <Divider><Text strong>Cuenta</Text></Divider>
      <li onClick={() => history.push('/account')}><UserOutlined /> Mi Cuenta</li>
      <li onClick={() => dispatch(userSignOut())}><PoweroffOutlined /> Cerrar sesión
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar icon={<UserOutlined />}  src={`${process.env.REACT_APP_BACK}${user.photo}`}
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  )

}

export default UserInfo;
