import React, {useEffect, useState} from "react";
import { message, Result, Button, Skeleton } from "antd";
import { useParams, Link } from "react-router-dom";
import { getActivationPacient } from "appRedux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";

const VerifyPacient = () => {
	const { token } = useParams();
	const dispatch = useDispatch();
	//Responses redux commos
	const error = useSelector(({common}) => common.error);
	const active = useSelector(({auth}) => auth.active);
	const [type, setType] = useState(null);

	useEffect(() => {
		if(active != null){
			if(active == true){
				setType(true);
				message.success("Cuenta activada exitosamente");
			}else{
				setType(false);
				//message.success("Cuenta activada exitosamente");
			}
		}
		//dispatch(resetActionCommon());
	}, [active]);

	useEffect(() => {
		dispatch(getActivationPacient(token, "pacient_activation"));
	}, []);

	useEffect(() => {
		if (error) {
			message.info(error);
		}       
	}, [error]);

	return (
		<div className="gx-page-error-container">
			<div className="gx-page-error-content">
				{ type == null ? <Skeleton/> : type ? <Result
					status="success"
					title="Cuenta activada!"
					subTitle="Por favor inicia sesion"
					extra={[
						<Button type="primary" key="console">
							<Link to="/signin">Ir al inicio</Link>
						</Button>,
					]}
				/> : <Result
					status="error"
					title="Error al activar cuenta!"
					subTitle="El enlace expiró o no es el correcto"
					extra={[
						<Button type="primary" key="console">
							<Link to="/signin">Ir al inicio</Link>
						</Button>,
					]}
        
				/>}
			</div>
		</div>
	);
};

export default VerifyPacient;
