import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import {connectRouter} from "connected-react-router";
import Pacient from "./Pacient";
import Account from "./Account";
import Orders from "./Orders";
import Subaccounts from "./Subaccounts";
import Prescriptions from "./Prescriptions";
import Histories from "./Histories";
import Variables from "./Variables";

export default (history) => combineReducers({
	router: connectRouter(history),
	settings: Settings,
	auth: Auth,
	common: Common,
	pacient: Pacient,
	account: Account,
	orders: Orders,
	subaccounts: Subaccounts,
	prescriptions: Prescriptions,
	Histories: Histories,
	variables: Variables
});
