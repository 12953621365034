/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from "react";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


const Descarga = () => {
	 

	return (
		<div className="gx-app-login-wrap" style={{backgroundColor: '#1B32AB'}}>
			<div className="gx-app-login-container-descarga">
				<div className="gx-app-login-main-content-descarga">
					<div className="gx-app-login-content-descarga" style={{textAlign: 'center'}}>
						<div style={{margin: '5%'}}>
							<img src="https://api.novacita.com/logo.png"></img>
						</div>
						<div style={{margin: '5%', alignItems: 'center'}}>
							<a
								style = {{
									display: 'inline-block',
									overflow: 'hidden',
									textAlign: 'center',
									background: 'url(https://api.novacita.com/appstore.svg) no-repeat',
									width: 140,
									height: 60,
								}}
								href  = "https://apps.apple.com/us/app/novacita/id1624597182"
							>
							</a>
							<a
								style = {{
									display: 'inline-block',
									overflow: 'hidden',
									background: 'url(https://api.novacita.com/googleplay.svg) no-repeat',
									width: 140,
									height: 60,
								}}
								href  = "https://play.google.com/store/apps/details?id=com.novacitasoft.novacita"
							>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Descarga;
