import React, { useCallback, useEffect } from "react";
import { Layout } from "antd";

import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import Footer1 from "routes/Landing/Footer1";
import App from "routes/index";
import {useDispatch, useSelector} from "react-redux";
import {
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_DRAWER,
	NAV_STYLE_FIXED,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
	NAV_STYLE_MINI_SIDEBAR,
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import {useRouteMatch} from "react-router-dom";
import {getVariables} from "appRedux/actions/Variables";
import "styles/less/antMotionStyle.less";
import {
	Footer10DataSource,
} from "routes/Landing/data.source";
import Download from "components/Common/download";
import CookieConsent from "react-cookie-consent";

const {Content, Footer} = Layout;

const MainApp = () => {

	const {navStyle} = useSelector(({settings}) => settings);
	const match = useRouteMatch();
	const dispatch = useDispatch();  
  

	const getMenu = useCallback ( () => { 
		dispatch(getVariables());
	}, [] );

	useEffect(() => {
		getMenu();
	},[getMenu]);

	const getContainerClass = (navStyle) => {
		switch (navStyle) {
		case NAV_STYLE_DARK_HORIZONTAL:
			return "gx-container-wrap";
		case NAV_STYLE_DEFAULT_HORIZONTAL:
			return "gx-container-wrap";
		case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
			return "gx-container-wrap";
		case NAV_STYLE_BELOW_HEADER:
			return "gx-container-wrap";
		case NAV_STYLE_ABOVE_HEADER:
			return "gx-container-wrap";
		default:
			return "";
		}
	};
	// eslint-disable-next-line no-unused-vars
	const getNavStyles = (navStyle) => {
		switch (navStyle) {
		case NAV_STYLE_DEFAULT_HORIZONTAL :
			return <HorizontalDefault/>;
		case NAV_STYLE_DARK_HORIZONTAL :
			return <HorizontalDark/>;
		case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
			return <InsideHeader/>;
		case NAV_STYLE_ABOVE_HEADER :
			return <AboveHeader/>;
		case NAV_STYLE_BELOW_HEADER :
			return <BelowHeader/>;
		case NAV_STYLE_FIXED :
			return <Topbar/>;
		case NAV_STYLE_DRAWER :
			return <Topbar/>;
		case NAV_STYLE_MINI_SIDEBAR :
			return <Topbar/>;
		case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
			return <NoHeaderNotification/>;
		case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
			return <NoHeaderNotification/>;
		default :
			return null;
		}
	};

	return (
		<Layout className="gx-app-layout">
			<Layout>
				<Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
					{/* {isMobile ? 
        <Row>
          <Col span={4}>
          </Col>
          <Col span={6}>
          </Col>
          <Col span={6}>
            <Button>Descargar</Button>
          </Col>
        </Row>
        : null} */}     
					<Download />   
					<App match={match}/>
					<CookieConsent            
						//debug
						location="bottom"
						buttonText="Aceptar"
						declineButtonText="No acepto, salir"
						enableDeclineButton
						cookieName="novacita_cookies"
						style={{ background: "#3C89F6" }}
						buttonStyle={{ color: "white", background: "#1B32AB", fontSize: "13px", borderRadius: "5px"}}
						declineButtonStyle={{ borderRadius: "5px"}}
						flipButtons
						onDecline={() => window.location = "https://google.com/"}
					>
            Este sitio web utiliza cookies para brindarle una excelente experiencia online. Al utilizar novacita.com, está permitiendo el uso de cookies de acuerdo con nuestra <a style={{ color: "#1B32AB" }} target="__blank" href="https://landing.novacita.com/politicas/">política de privacidad</a>
					</CookieConsent>
					<Footer>
						<Footer1
							id="Footer1_0"
							key="Footer1_0"
							dataSource={Footer10DataSource}
							isMobile={true}
						/></Footer>
					{/* <Footer>
            <div className="gx-layout-footer-content">
              {footerText}
            </div>
          </Footer> */}
				</Content>
			</Layout>
		</Layout>
	);
};
export default MainApp;

