import React, { useState } from "react";
import {Image, Layout, Row, Col, Space, Button} from "antd";
import {isMobile} from 'react-device-detect';
import { UserOutlined } from "@ant-design/icons";

const Download = () => {
    const [apps, setApps] = useState(true);

  return (
    isMobile ? 
        apps ? <Row style={{backgroundColor: 'white', padding: '2px'}} align="top">          
       <Space size='large' >         
         <div style={{paddingLeft: '20px', paddingRight: '15px'}}>   
         <span style={{verticalAlign: 'top', fontWeight: 'bold', fontSize: '20px', paddingRight: '5px'}} onClick={() => setApps(false)}>x</span>
         <Image
           //size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
           src="https://api.novacita.com/icono.png"
           shape="square"
           width={50}
           height={50}
           preview={false}
           style={{borderStyle: 'solid', padding: '5px', borderRadius: '10px'}}
         />
         </div>
         <div>
           <p style={{fontWeight: 'bold', marginBottom: '0px', fontSize: '14px'}}>NOVACITA</p>
           <p style={{marginBottom: '0px', fontSize: '12px'}}>Reserva fácil y rápido</p>
           {/* <p style={{marginBottom: '0px', fontSize: '12px'}}>GRATIS - Google Play y App Store</p> */}
         </div>
         <div style={{float: 'right', position: 'relative'}}>     
           <a
             style = {{
               display: 'inline-block',
               overflow: 'hidden',
               background: 'url(https://api.novacita.com/appstore.svg) no-repeat',
               width: 165,
               height: 40,
             }}
             href  = "https://apps.apple.com/us/app/novacita/id1624597182"
             >
           </a>
           <a
             style = {{
               display: 'inline-block',
               overflow: 'hidden',
               background: 'url(https://api.novacita.com/googleplay.svg) no-repeat',
               width: 165,
               height: 40,
             }}
             href  = "https://play.google.com/store/apps/details?id=com.novacitasoft.novacita"
             >
           </a>
         </div>    
         </Space>
       </Row> : null 
       : null
  )
};

export default Download;