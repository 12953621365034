import React from "react";
import {Avatar} from "antd";


const NotificationItem = ({notification}) => {
  const {data, type } = notification;
  return (

    <li className="gx-media">
      {type == 'App\\Notifications\\RemiderAppointment' ? 
      <>{/* <Avatar className="gx-size-40 gx-mr-3 gx-text-blue"
              alt="R" >R</Avatar> */}
      <div className="gx-media-body gx-align-self-center">
        {/* <p className="gx-fs-sm gx-mb-0">{JSON.stringify(data.appointment.doctor)}</p>
        <p className="gx-fs-sm gx-mb-0">{JSON.stringify(data.appointment.detail)}</p> */}
        <p className="gx-fs-sm gx-mb-0"><i className={`icon icon-profile gx-text-grey gx-pr-2`}/> <span className="gx-meta-date">{data.appointment.doctor}</span></p>
        <p className="gx-fs-sm gx-mb-0"><i className={`icon icon-home gx-text-grey gx-pr-2`}/> <span className="gx-meta-date">{data.appointment.detail}</span></p>
        <p className="gx-fs-sm gx-mb-0"><i className={`icon icon-calendar gx-text-grey gx-pr-2`}/> <span className="gx-meta-date">{data.appointment.date_appointment} - {data.appointment.start_at}</span></p>
      </div></> : null}
    </li>
  );
};

export default NotificationItem;
